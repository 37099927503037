class Menu {
    constructor(mobileToggleButtonId) {
        console.log('INIT MENU');
        this.mobileToggleButtonId = mobileToggleButtonId;
        /**
         * @type {HTMLElement}
         */
        this.mobileMenuToggleButton = null;
        this.menuSelector = '#menu > ul.menu';
        /**
         * @type {HTMLElement}
         */
        this.menu = null;
        this.setMenuEl();
        this.mobileMenuInit();

        this.fixtureForAndroidTablet();
    }

    mobileMenuInit () {
        this.setMobileToggleButtonEl();
        this.setMobileToggleButtonEventHandler();
    }

    setMobileToggleButtonEl () {
        this.mobileMenuToggleButton = document.getElementById(this.mobileToggleButtonId);
    }
    setMenuEl () {
        this.menu = document.querySelector(this.menuSelector);
    }

    mobileButtonEventHandler () {
        if (this.menu) {
            if (this.menu.classList.contains('showMobile')) {
                this.menu.classList.remove('showMobile');
                this.mobileMenuToggleButton.classList.remove('active');
                document.querySelector('html').style.overflow = '';
            } else {
                this.menu.classList.add('showMobile');
                document.querySelector('html').style.overflow = 'hidden';
                this.mobileMenuToggleButton.classList.add('active');
                var iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
                if (iOS && window.innerHeight > 700) {
                    if (this.menu) {
                        $(this.menu).attr('style', 'max-height: calc(100vh - 200px) !important');
                    }
                }
            }
        }
    }

    setMobileToggleButtonEventHandler () {
        if (this.mobileMenuToggleButton) {
            this.mobileMenuToggleButton.onclick = (e) => {
                this.mobileButtonEventHandler();
            };
        }
    }

    fixtureForAndroidTablet () {
        const md = new MobileDetect(window.navigator.userAgent);

        if (md.tablet() && this.menu && !this.menu.classList.contains('showMobile')) {
            const menuItems = this.menu.querySelectorAll('li.menu-item.submenu-holder');
            menuItems.forEach((menuItem) => {
                const menuLink = menuItem.querySelector('.menu-item-div');
                menuLink.onclick = (e) => {
                    e.preventDefault();
                };
            });
        }
    }
}

export default Menu;